.div_multiple_select {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.div_align_multiple_select_input {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.div_align_multiple_select_input select {
    margin: 0;
    padding: 0.5rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 75%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    height: fit-content;
    align-self: center;
}

.div_align_multiple_select_input, .div_algin_multiple_select_form {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
}