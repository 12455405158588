.ImageInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30vh;
}

.div_label_input_image {
    min-height: 13vh;
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.div_align_input_image {
    width: 100%;
    min-height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 0.2rem;
    padding-top: 0.2rem;
}

.div_align_image_input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 20vh;
    max-height: 270px;
}

.div_align_input_image button {
    height: 80%;
    max-height: 3rem;
}

.div_field_input_image {
    margin: 0;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    border-radius: 0.25rem;
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 25vh;
}

.div_edit_list_images {
    background-color: transparent;
    width: 100%;
    height: 25vh;
}

.div_message_noimage_input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
}

.div_message_noimage_input h1 {
    font-size: 20px;
}