@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300&display=swap');

.CaeRegister {
  background: #EBF1FF;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  padding-top: 107px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main-container-cae-register {
  width: 957.5px;
  min-height: 538px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 28px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  overflow: hidden;
  background-color: white;
  gap: 38px;
  padding-top: 48px;
  padding-bottom: 20px;
}

.header-container-cae-register { 
  width: 62.5%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  gap: 2px;
}

.header-container-cae-register span:first-child {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header-container-cae-register span:last-child {
  color: #42526E;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.div-title-cae-register { 
  width: 62.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.div-title-cae-register h1 {
  color: #172B4D;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}

.div-title-cae-register h6 {
  font-family: Inter;
  color: #42526E;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.div-body-cae-register {
  width: 62.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  gap: 3px;
  padding-top: 15px;
}

.div-body-cae-register p {
  color: #42526E;
  font-family: Inter;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: justify;
}

.div-body-cae-register ul {
  stroke-width: 5px;
  color: #42526E;
  font-family: Inter;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  list-style-type: circle;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 18px;
  padding-left: 32px;
}

.line-break-cae-register {
  background: #EBECF0;
  height: 2px;
  width: 100%;
  margin: 0 auto;
  margin-top: 14px;
}

.div-terms-accepted {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  padding-left: 16px;
  padding-top: 30px;
}

.div-radio-cae {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding-left: 16px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.label-terms-text {
  color: #42526E;
  font-family: Inter;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


/* Checkbox css */
.label-terms-accepted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 13px;
  width: 13px;
}

.label-terms-accepted input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark-terms {
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: #eee;
  border: 1px solid #eee;
  transition: all 100ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
}

.checkmark-radio-cae {
  border-radius: 50px;
}

.label-terms-accepted:hover input ~ .checkmark-terms {
  background-color: #ccc;
  border-color: #172B4D;
}

.label-terms-accepted input:checked ~ .checkmark-terms {
  background-color: #172B4D;
  border-color: #172B4D;
}

.checkmark-terms:after {
  content: "";
  position: absolute;
  display: none;
}

.label-terms-accepted input:checked ~ .checkmark-terms:after {
  display: block;
}

.label-terms-accepted .checkmark-terms:after {
  align-self: center;
  justify-self: center;
  width: 3px;
  height: 7px;
  border: 1px solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: -1px;
}

.div-buttons-cae-register {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 82px;
  gap: 20px;
}

.div-buttons-cae-register button {
  width: 120px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #172B4D;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: #FFF;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  transition: all 200ms ease-in-out;
} 

.div-buttons-cae-register button:last-child:hover {
  background: #111C30;
}

.div-buttons-cae-register button:last-child:disabled {
  background: #111C30;
  cursor: default;
}

.div-buttons-cae-register button:first-child:hover {
  background: #EBECF0;
}


.div-buttons-cae-register button:active, .div-buttons-cae-register button:focus {
  outline: none;
}

.div-buttons-cae-register button:first-child {
  border: 1px solid #EBECF0;
  background: #FFF;
  color: #A3A3A3;
}

.div-align-radio-cae-register {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
}

.div-form-cae-register { 
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.div-form-cae-register span {
  color: #172B4D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 30px;
}

.error-message-form-cae {
  color: #DA3F21 !important;
  font-size: 13px !important;
  width: 100%;
  margin-top: -20px;
}

@media only screen and (max-width: 1200px) {
  .CaeRegister {
    padding-top: 15px;
  }

  .main-container-cae-register {
    width: 95%;
  }

  .container-cae-login:first-child {
    width: 100%;
  }

  .container-cae-login:last-child {
    display: none;
  }
  
  .header-container-cae-register, .div-title-cae-register, .div-body-cae-register {
    width: 80%;
  }
}

@media only screen and (max-width: 575px) {
  .CaeRegister {
    padding-top: 0;
  }

  .main-container-cae-register {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
    border-radius: 0;
    padding-top: 25px;
    
  }

  .container-cae-login:first-child {
    width: 100%;
    height: 100%;
  }

  .main-container-cae-login {
    border-radius: 0;
  }

  .container-cae-login:last-child {
    display: none;
  }
  
  .header-container-cae-register, .div-title-cae-register, .div-body-cae-register {
    width: 90%;
  }

  .FieldFormCaeExplanation span{
    margin-top: 10px !important;
  }
}