.CardDashboard {
    width: 98%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 8px;
    border-top: 5px solid var(--eleventhcolor);
    transition: all 300ms ease-in-out;
}

.div_headear_carddashboard {
    width: 100%;
    height: 10%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #f5f5f5;
}

.div_headear_carddashboard h1 {
    align-self: center;
}

.btn_close_carddashboard {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

.btn_close_carddashboard:focus {
    border: none;
    outline: none;
}

.arrow_down_carddashboard {
    width: 100%;
}

.div_headear_carddashboard h1 {
    font-size: 15px;
    font-weight: 800;
    margin: 8px;
}

.div_body_carddashboard {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_bodyleft_carddashboard {
    width: 27%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.div_info_left_carddashboard {
    width: 100%;
    height: 22%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.div_info_left_carddashboard h1 {
    font-size: 22px;
    font-weight: 500;
}

.div_info_left_carddashboard p{
    color: #1ab394;
    font-weight: 500;
}

.div_bodyright_carddashboard {
    width: 73%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.div_info_carddashboard {
    width: 100%;
    height: 18%;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_top_info_carddashboard {
    width: 100%;
    height: 77%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div_left_info_carddashboard {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.div_right_info_carddashboard {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.h1_info_carddashboard {
    font-size: 19px;
    font-weight: 800;
    margin: 0;
    padding: 0;
}

.h3_info_carddashboard {
    font-size: 15px;
    font-weight: 900;
}

.div_porcent_outbar {
    background-color: #f5f5f5;
    width: 90%;
    border-radius: 10px;
    overflow: hidden;
}

.div_porcent_innerbar {
    background-color: #1ab394;
    height: 6px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

@media only screen and (max-width: 600px) {
    .CardDashboard {
        width: 80%;
        margin: 0;
        padding-top: 0.5rem;
    }

    .div_info_left_carddashboard p{
        font-size: 10px;
    }

    .h1_info_carddashboard {
        font-size: 15px;
    }

    .div_info_left_carddashboard h1 {
        font-size: 16px;
        font-weight: 700;
    }

    .h3_info_carddashboard {
        font-size: 13px;
    }

    .div_left_info_carddashboard p {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1700px) {
    .div_info_left_carddashboard p{
        font-size: 1.1rem;
    }

    .h1_info_carddashboard {
        font-size: 2rem;
    }

    .div_headear_carddashboard h1 {
        font-size: 1.4rem;
    }

    .div_info_left_carddashboard h1 {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .h3_info_carddashboard {
        font-size: 1.5rem;
    }

    .div_left_info_carddashboard p {
        font-size: 1.1rem;
    }
}