@media (max-width: 768px) {
  .slick-slider{ /*Wraps the carousel*/
    width: 100%;
  }
}

.slick-track{
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
.slick-arrow {
  background-color: grey;
}
.slick-arrow:hover {
  background-color: grey;
}
.slick-arrow:focus {
  background-color: grey;
}
.slick-arrow {
  background: inherit !important;
  z-index: 5 !important;
}
.slick-arrow:hover {
  opacity: 0.6 ;
}
.slick-next {
  margin-right: 15%;
}

.slick-prev {
  margin-left: 10%;
  left: 0px;
}