.LocalImage {
    min-width: fit-content;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 0.25rem;
    padding: 0;
}

.icon_trash_img {
    background-color: var(--ninethcolor);
    border-radius: 6px;
    width: 25px;
    margin: 3px auto;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}

.icon_trash_img:hover {
    background-color: var(--thirteenthcolor);
}

.icon_trash_img:active {
    background-color:var(--ninethcolor);
}

.img_edit_inventory_admin {
    width: auto;
    margin: 0 auto;
    object-fit: fill;
    height: 100%;
}