.div_align_button_form_question {
    width: 100%;
    background-color: white;
    margin-top: 2rem;
    border-top: 5px solid var(--eleventhcolor);
}

.div_align_form_question {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
    overflow: hidden;
    transition: all 300ms ease-in-out;
}

.form_questions_demand {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
}

.div_add_new_question {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 10vh;
}

.div_btn_add_question {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 130px;
    height: 40px;
    background-color: var(--ninethcolor);
    border-radius: 0.25rem;
    margin: 0 auto;
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
}

.div_btn_add_question:hover {
    background-color: var(--fourteenthcolor);
}

.img_add_question {
    width: 20%;
    margin: 0 auto;
}

.div_btn_add_question span {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.9rem;
    text-align: left;
}