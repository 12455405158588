.RangeInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 13vh;
    max-height: 80px;
}

.div_label_input_range {
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.div_out_input_text {
    width: 100%;
    align-self: center;
}

.div_out_input_text input {
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.div_out_input_text input::placeholder {
    color: var(--eighthcolor);
}

.div_out_input_text input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

input[type=range]{
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--ninethcolor);
    margin-top: -4px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: #43e5f7; 
}
input[type="range"]::-moz-range-track {  
    background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7; 
}
input[type="range"]::-ms-fill-upper {  
    background-color: #9a905d;
}