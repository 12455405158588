body {
    overflow-x: hidden;
}

.SidebarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 100vh;
    height: fit-content;
    width: 100%;
    z-index: 2;
}

.SideBarBody {
    width: 82%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60px;
    grid-template-areas:
        'header'
        'pages'
    ;
    transition: all 500ms ease-in-out;
    z-index: 2;
}

.div_align_sidebar {
    width: 18%;
    transition: all 500ms ease-in-out;
    background: none;
    z-index: 2;
}

.iconSidebar:active {
    animation: ease-item 0.5s linear;
}

@keyframes ease-item {
    0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
}

@keyframes ease-item-delay {
    0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
}

.HeaderAdminPage {
    grid-area: header;
    z-index: 2;
}

.SidebarPages {
    grid-area: pages;
    z-index: 2;
}

.Sidebar{
    background-color: var(--thirdcolor);
    width: 18%;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    overflow-x: scroll;
    transition: all 500ms ease-in-out;
    z-index: 10;
    scrollbar-color: var(--thirdcolor) var(--thirdcolor);
    scrollbar-width: none;
}

.Sidebar::-webkit-scrollbar {
    width: 5px;
    height: 0;
    background-color: var(--thirdcolor);
}

.Sidebar::-webkit-scrollbar-thumb {
    background-color: var(--thirdcolor);
}

.SidebarList, .SidebarSubList{
    height: auto;
    width: 100%;
    padding: 0;
}

.SidebarSubList {
    transition: all 300ms ease-in-out;
    height: 0;
    overflow: hidden;
}

.SidebarList .rowSidebarItem, .rowSidebarSubItem {
    width: 100%;
    height: 4vw;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    color: var(--sixthcolor);
    justify-content: flex-start;
    align-items: center;
    font-weight: 1000;
    font-size: 1vw;
}

.rowSidebarSubItem {
    height: 6vh;
}

.titleSidebarSubItem {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
}

.SidebarList .rowSidebarItem:hover, .rowSidebarSubItem:hover{
    cursor: pointer;
    background-color: var(--fourthcolor);
    color: var(--firstcolor);
}

.SidebarList #active {
    background-color: var(--fourthcolor);
    color: var(--firstcolor);
}

.rowSidebarItem #icon {
    flex: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.15rem !important;
}

.rowSidebarItem #title {
    flex: 70%;
}

.rowSidebarSubItem #title {
    padding-left: 2rem;
    flex: 100%;
}

.rowSidebarItem #img_arrow {
    flex: 10%;
}

.SidebarName {
    color: var(--firstcolor);
    font-size: 1vw;
    transition: all 300ms ease-in-out;
}

.SidebarHeader{
    background: linear-gradient(
    180deg,
    var(--fifthcolor) 40%,
    var(--fourthcolor) 100%
    );
    padding-bottom: 10%;
    padding-left: 10%;
    padding-top: 10%;
    font-size: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.logoHeader {
    width: 90%;
    max-width: 60px;
}

.ImageSidebar{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.titleSidebar {
    opacity: 1;
    transition: all 250ms ease-in-out;
}

.arrow_down_image {
    width: 35px;
    transform: rotate(-90deg);
    transition: all 300ms ease-in-out;
}