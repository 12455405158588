.body_header_admin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 60px;
}

.div_btn_sidebar {
    background-color: var(--firstcolor);
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 60px;
    width: 250px;
}

.btn_header_adm {
    background-color: #18A689;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 42px;
    width: 3.3vw;
    min-height: 40px;
    height: 3vw;
    align-self: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.btn_header_adm:hover {
    background-color: #0cddb3;
}

.strip_btn_header_adm {
    background-color: white;
    min-width: 10px;
    width: 1.2vw;
    min-height: 2px;
    height: 0.2vw;
    margin: 1px auto;
}

.div_title_header {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
}

@media only screen and (min-width:3840px){
    .div_title_header{
        height: 100%;
        width: 200%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 20px;
        padding-left: 5px;
    }
}

@media only screen and (min-width:3840px){
    .strip_btn_header_adm {
        background-color: white;
        width: 0.6vw;
        height: 0.1vw;
        margin: 1px auto;
    }
}

@media only screen and (min-width:3840px){
    .btn_header_adm {
        background-color: #18A689;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60px;
        height: 50px;
        align-self: center;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 5px;
    }
}

.div_logout_adm {
    height: 100%;
    width: 23vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn_logout_adm {
    width: 70%;
    background-color: transparent;
    border: none;
    outline: none;
}

.btn_logout_adm:active {
    outline: none;
    border: none;
}

.div_select_city_header {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_select_city_header label, .div_select_city_header select {
    height: 20%;
    justify-self: center;
    align-self: center;
    color:  #b8b7b4;
    font-size: 16px;
}

.div_select_city_header select {
    width: 60%;
    height: 50%;
    color: black;
}

.icon_logout_header {
    width: 20%;
}

.SidebarUserCity {
    font-weight: 600;
}

.SidebarUserName {
    color: #8095a8;
}