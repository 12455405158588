.EditTemplates {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.div_align_body_edit_templates {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    min-height: 85vh;
}

.btn_goback_templates {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 0.25rem;
    background-color: var(--ninethcolor);
    border: none;
    border-radius: 0.25rem;
    outline: none;
    transition: all 200ms ease-in-out;
    height: 2.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.btn_goback_templates span {
    color: white;
    font-size: 14px;
    align-self: center;
    justify-self: center;
}

.header_edit_template {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.header_edit_template h1 {
    font-size: 20px;
    font-weight: 600;
}

.header_edit_template span {
    font-size: 14px;
}

.body_edit_template {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.card_template_default {
    width: 180px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    margin: 10px;
}

.card_template {
    width: 180px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    overflow: hidden;
    margin: 10px;
}

.card_template:hover,.card_template_default:hover {
    scale: 1.02;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.div_template_doc {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: var(--thirdcolor);
}

.div_img_templateDoc {
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--fourthcolor);
}

.div_img_templateDoc img {
    height: 50%;
    align-self: center;
}

.card_template_default img {
    width: 70px;
    margin: 0 auto;
}

.card_template_default span {
    font-size: 14px;
    color: #696C6E;
    width: 70%;
    margin: 0 auto;
    font-family: Verdana,Geneva,sans-serif; 
}

.card_template span {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    font-size: 15px;
    flex-direction: column;
    justify-content: center;
    font-family: Verdana,Geneva,sans-serif;
    font-weight: 400;
}

.div_my_templates {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.table_my_templates {
    width: 100%;
}

.table_my_templates tr {
    border-bottom: 1px solid var(--sixthcolor);
}

.tr_td_my_template {
    cursor: pointer;
}

.tr_td_my_template:hover {
    background-color: var(--twenthcolor);
}

.table_my_templates td {
    padding: 10px;
    font-family: Verdana,Geneva,sans-serif; 
    font-size: 14px;
}

.table_my_templates th {
    padding: 10px;
    font-family: Verdana,Geneva,sans-serif; 
    font-size: 15px;
    font-weight: 400;
    color: var(--seventhcolor);
}

