.SelectInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 13vh;
    max-height: 80px;
}

.div_select_input_select {
    width: 75%;
    align-self: center;
}

.div_select_input_select select {
    margin: 0;
    padding: 0.5rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.div_select_input_select select:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}