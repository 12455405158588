.TextareaInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 250px;
}

.div_align_textareaInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 100%;
}

.div_label_input_textarea {
    width: 18%;
    height: 13vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.label_textarea_input {
    width: 100%;
    text-align: right;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    padding-top: 2rem;
    margin: 0;
}

.div_out_input_textarea {
    width: 100%;
    min-height: 30vh;
    /* max-height: 150px; */
    align-self: center;
    display: block;
    flex-direction: column;
    justify-content: center;
}
 
.textarea_input_comp, .div_out_input_textarea textarea {
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    min-height: 30vh;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.textarea_input_comp:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.div_align_explanation_textarea {
    min-height: 100%;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
}

.div_explanation_textarea {
    padding-top: 0.5rem;
    height: 30px;
}