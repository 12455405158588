.inputFilters {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 20vh;
}

.div_align_multiple_select_do_nelli{
    width: 100%;
    padding-top: 10px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    
}

.div_align_explanation_input_do_nelli{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    min-height: 13vh;
    padding-top: 1rem;
}


.filter-dropdown {
    display: flex;
    flex-direction: row;
    justify-content:left;
    height: 4vh;
    border-radius: 0.25rem;
}

.select_inventory_do_nelli{
    font-weight: lighter;
    font-size: 13px;
    height: 25vh;
    width: 75%;
    margin: 0;
    padding: 6px;
    color: rgba(128, 128, 128, 0.849);
    border: 0.5px solid #e8e9ea;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    overflow: auto;
    border-radius: 0.25rem;
}

.div_selects_neigh_align select{
    height: 100%;
    padding: 0.4rem;
    width: 43%;
    border-radius: 0.25rem;
}

.div_explanation_input {
    height: 5vh;
    width: 100%;
    margin: auto auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 1rem;
}

.btn_handle_neighborhood{
    border: none;
    background-color: var(--ninethcolor);
    width: 60%;
    height: 45%;
    max-height: 50px;
    margin: 0 auto;
    box-shadow: 0 7px rgba(0, 0, 0, 0.4);
    transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
    outline: none;
    border-radius: 0.25rem;
}

.btn_handle_neighborhood:hover{
    background-color: var(--fourteenthcolor);
}

.btn_handle_neighborhood:active{
    transform: translateY(3px);
    box-shadow: 0 4px rgba(0, 0, 0, 0.6);
    outline: none;
}

.btn_handle_neighborhood:focus{
    outline: none;
}