@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.PlaceID {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.32);
    z-index: 100;
    top: 0;
    left: 0;
}

.inner_popup_placeid {
    width: 600px;
    height: 650px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    position: relative;
    gap: 10px;
    padding: 30px;
}

.header_placeid {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    text-align: left;
    position: relative;
    padding: 25px;
}

.title_header_placeid {
    font-size: 22px;
    font-weight: 500;
    font-family: 'Lato';
    text-align: center;
}

.subtitle_header_placeid {
    font-size: 16px;
    font-weight: 200;
    font-family: 'Lato';
    color: var(--sixthcolor);
    margin: 0 auto;
}


.div_field_placeid {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 25px;
    height: 100px;
    position: relative;
}

.div_field_placeid label {
    position: absolute;
    left: 50px;
    top: 15px;
    background-color: white;
    z-index: 3;
    font-size: 14px;
    width: 120px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #172B4D;
}

.input_place_id {
    width: 100%;
    border: 2px solid #172B4D;
    border-radius: 10px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: transparent;
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 10px;
    padding-left: 15px;
}

.map_place_id {
    width: calc(100% - 50px);
    height: 400px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.div_buttons_placeid {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    padding-right: 25px;
    align-items: center;
}

.div_buttons_placeid button {
    width: 110px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    outline: none;
}

.btn_confirm_placeid {
    background-color: #172B4D;
    color: white;
    border: none;
}

.btn_cancel_placeid {
    background-color: #EBECF0;
    border: none;
}

.btn_confirm_placeid:hover {
    background-color: #111e36;
}

.btn_cancel_placeid:hover {
    background-color: #c5c7ce;
}

.btn_cancel_placeid:active, .btn_confirm_placeid:active {
    outline: none;
}

.btn_confirm_placeid:disabled {
    background-color: #0e182c;
}