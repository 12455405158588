.container {
  max-height: fit-content;
  width: 100%;
  display: flex;
  flex: 1;
  text-align: left;
}

.data-container {
  flex: 0.5;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.pink-line {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom-color: #db3d78;
  border-bottom-style: solid;
  border-bottom-width: 7px;
  width: 35px;
}

.photo-container {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center
}

.big-size-font  {
  margin: 0px;
  font-size: medium;
}

.medium-size-font  {
  margin: 0px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  font-size: small;
}

.small-size-font  {
  margin: 0px;
  font-size: smaller;
}