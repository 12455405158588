.FieldReview {
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;    
    border: 1.5px solid var(--sixthcolor);
    border-radius: 0.5rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    margin-bottom: 1rem;
}

.new_field_review {
    background-color: var(--sixthcolor);
}

.title_field_review {
    width: 20%;
    align-self: center;
    font-weight: 900;
    text-align: right;
    font-size: 14px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input_field_review {
    width: 80%;
    height: 60%;
    border: none;
    background-color: transparent;
    outline: none;
    padding-left: 1rem;
    font-size: 14px;
    resize: none;
    align-self: center;
    overflow: auto;
}

.input_field_review::-webkit-scrollbar {
    background: transparent;
    height: 100%;
    width: 5px;
}

.input_field_review::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.849);
    border-radius: 40px;    
    height: 20%;
}