.GeolocationInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
}

.div_align_input_geolocation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_label_input_geolocation {
    width: 18% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.div_field_input_geolocation {
    margin: 0;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    width: 100%;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_field_input_geolocation input {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 200ms ease-in-out;
    width: 100%;
    padding: 0.5rem;
    color: var(--eighthcolor);
}

.div_field_input_geolocation input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.div_field_input_geolocation input::placeholder {
    color: var(--eighthcolor);
}

.div_field_input_geolocation button {
    width: 45px;
    height: 35px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.div_field_input_geolocation button img {
    width: 90%;
}