.RodapeAdmin {
    width: 100% + 1rem;
    height: 7vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    bottom: 0;
    left: 0;
}

.txt_rodape_admin {
    text-align: left;
    color: gray;
    margin: 0;
    padding-left: 0.5rem;
}