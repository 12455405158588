/* Columns */
.row_table_inventory td {
    text-align: center;
}

.body_table {
    background-color: var(--firstcolor);
}

.title_table_inventory {
    width: 10%;
    padding: 7px;
}

.desc_table_inventory {
    width: 34%;
    padding: 7px;
}

.column_min_table_inventory {
    width: 5%;
    height: 100%;
}

/* Div's buttons */
.div_mod_inventory {
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Buttons */
.btn_mod_adm {
    cursor: pointer;
    border: none;
    background: transparent;
    color: #8ac0ec;
    margin: 5px;
}

.btn_mod_adm:hover {
    text-decoration: none;
    color: #4a81ad;
}