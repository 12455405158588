.AdderInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 13vh;
}

.div_label_input_adder {
    min-height: 13vh;
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.div_align_adder_input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 15vh;
    max-height: 100px;
}

.div_align_explanation_input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 75%;
    min-height: 13vh;
    padding-top: 1rem;
}

.div_field_input_adder {
    margin: 0;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    border-radius: 0.25rem;
    display: flex;
    width: 100%;
    align-self: center;
    flex-direction: row;
    justify-content: center;
    height: fit-content;
}

.div_explanation_input {
    width: 100%;
    padding-top: 0.2rem;
}

.subtext_explanation_input {
    font-weight: normal;
    color: rgba(128, 128, 128, 0.849);
    width: 100%;
    font-size: 14px;
    margin: 0;
    padding: 2px;
}

.div_field_input_adder input {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 200ms ease-in-out;
    width: 90%;
    padding: 0.5rem;
    color: var(--eighthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
}

.div_field_input_adder:focus-within {
    padding: 0;
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.div_field_input_adder input::placeholder {
    color: var(--eighthcolor);
}

.div_field_input_adder button {
    width: 10%;
    height: 36px;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    outline: none;
    border: none;
    align-self: center;
}

.div_field_input_adder button:active {
    outline: none;
    border: none;
}

.div_field_input_adder button:focus {
    outline: none;
    border: none;
}

.div_align_activities {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 75%;
    margin-right: 2.3%;
    align-self: flex-end;
}

.div_activities_edit_adder_input {
    width: 100%;
}
