.SelectMultipleRender {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 20vh;
}

.div_align_multiple_select {
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.div_selects_neigh_align {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
}

.div_selects_neigh_align select{
    height: 100%;
    padding: 0.4rem;
    width: 43%;
    border-radius: 0.25rem;
}

.div_btns_set_neighborhood {
    height: 100px;
    width: 150px;
    margin: auto auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    grid-gap: 1rem;
    gap: 1rem;
}

.btn_handle_neighborhood{
    border: none;
    background-color: var(--ninethcolor);
    width: 60%;
    height: 45%;
    max-height: 50px;
    margin: 0 auto;
    box-shadow: 0 7px rgba(0, 0, 0, 0.4);
    transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
    outline: none;
    border-radius: 0.25rem;
}

.btn_handle_neighborhood:hover{
    background-color: var(--fourteenthcolor);
}

.btn_handle_neighborhood:active{
    transform: translateY(3px);
    box-shadow: 0 4px rgba(0, 0, 0, 0.6);
    outline: none;
}

.btn_handle_neighborhood:focus{
    outline: none;
}