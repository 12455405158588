.CardReportCalculator {
    text-decoration: none;
    color: #000;
    background: #ffc;
    height: 55vh;
    max-height: 310px;
    width: 80%;
    padding: 1em;
    position: relative;
    list-style-type: none;
    /* Firefox */
    -moz-box-shadow: 5px 5px 2px #212121;
    /* Safari+Chrome */
    -webkit-box-shadow: 5px 5px 2px rgba(33, 33, 33, 0.5);
    /* Opera */
    box-shadow: 5px 5px 7px rgb(33 33 33 / 70%);
    transition: all 200ms ease-in-out;
    margin: 1.2rem;
}

.style_card_deleted {
    background: lightgray;
}

.cardReportLeft {
    -webkit-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
}

.cardReportRight {
    -webkit-transform: rotate(4deg);
    -o-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
}

.cardReportLeft:hover, .cardReportRight:hover {
    scale: 1.1;
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
}

.div_align_card_report {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.div_align_card_report small {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
}

.div_align_card_report h4 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    font-family: inherit;
    margin-bottom: 10px;
}

.div_align_card_report p {
    font-size: 12px;
    margin: 0 0 10px;
}

.span_info_card_report {
    background-color: #1c84c6;
    font-family: 'Open Sans';
    color: #FFFFFF;
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
    border-radius: 0.25em;
    text-align: center;
    align-self: flex-end;
    height: fit-content;
    width: fit-content;
}

.div_align_card_report img {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 17px;
    cursor: pointer;
}