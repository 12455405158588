@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@100;300;400;700&display=swap');

.FooterLoginCae {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11px;
  margin-top: 50px;
}

.FooterLoginCae span {
  color: #172B4D;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.div-align-social-media {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 18px;
}

.div-align-social-media img {
  cursor: pointer;
  width: 20px;
}

@media only screen and (max-width: 575px) {
  .FooterLoginCae {
    display: none;
  }
}