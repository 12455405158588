.ExampleInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: fit-content;
}

.div_label_input_example {
    justify-content: flex-start;
    padding-top: 1rem;
}

.div_out_input_example {
    width: 100%;
    background-color: var(--secondcolor);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.img_example_input {
    width: 25%;
    margin: 0 auto;
}