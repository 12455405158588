/* Columns */
.row_table_itinerary{
    height: 26.625vh;
    overflow: hidden;
    transition: height ease 0.3s;
    background-color: rgb(229, 227, 240);
    text-align:center;
}

.row_table_itinerary div td {
    text-align: center;
}

.row_table_itinerary tr {
    text-align: center;
    height : 300px;
}

.row_table_itinerary_parent_show {
    height: auto;
}

.body_table {
    background-color: var(--firstcolor);
}

.title_table_itinerary {
    width: 15%;
    padding: 10px;
}

.desc_table_itinerary {
    text-align: center;
    width: 34%;
    padding: 7px;
}

.column_min_table_itinerary {
    text-align: center;
    width: 5%;
    height: 100%;
}

/* Div's buttons */
.div_mod_itinerary {
    text-align: center;
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Buttons */
.btn_mod_adm_itinerary {
    text-align: center;
    cursor: pointer;
    border: none;
    background: transparent;
    color: #8ac0ec;
    margin: 5px;
}

.btn_mod_adm:hover {
    color: #4a81ad;
}