@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300;400;500&display=swap');

.FieldFormCae {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FieldFormCaeExplanation {
  min-height: 110px;
  justify-content: space-between;
}

.FieldFormCae input, .FieldFormCae select, .FieldFormCae textarea {
  border-radius: 5px;
  border: 1px solid #EBECF0;
  background: #EBECF0;
  height: 35px;
  width: 100%;
  color: rgba(23, 43, 77, 0.62);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 20px;
  outline: none;
}

.FieldFormCae span {
  color: #42526E;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
  height: fit-content;
  margin-top: -25px;
}

.FieldFormCae textarea {
  height: 140px;
  padding-top: 15px;
}

.FieldFormCae select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-image: url(../../imgs/arrow_down_select_cae.svg);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  background-size: 10px;
}

.FieldFormCae select::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: rgba(23, 43, 77, 0.62);
}

.FieldFormCae select::-webkit-scrollbar {
  width: 3px;
}

.FieldFormCae input::placeholder, .FieldFormCae textarea::placeholder {
  color: rgba(23, 43, 77, 0.62);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.FieldFormCae label {
  color: #172B4D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.div-radio-cae-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  padding: 0;
  margin: 0;
  gap: 20px;
}

.div-align-radio-cae-form {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.radio-form-cae {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 18px;
  width: 18px;
}

.radio-form-cae input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check-icon-radio-form-cae {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border: 1px solid #eee;
  transition: all 100ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50px;
}

.radio-form-cae:hover input ~ .check-icon-radio-form-cae {
  background-color: #ccc;
  border-color: #172B4D;
}

.radio-form-cae input:checked ~ .check-icon-radio-form-cae {
  background-color: #172B4D;
  border-color: #172B4D;
}

.check-icon-radio-form-cae:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-form-cae input:checked ~ .check-icon-radio-form-cae:after {
  display: block;
}

.radio-form-cae .check-icon-radio-form-cae:after {
  align-self: center;
  justify-self: center;
  width: 3px;
  height: 7px;
  border: 1px solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: -1px;
}

.label-radio-form-cae {
  color: #42526E;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-align: justify;
  height: fit-content;
}
