.GeolocationInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
}

.div_align_input_geolocation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_label_input_geolocation {
    width: 13%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.div_field_input_geolocation {
    margin-bottom: 5px;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    width: 100%;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_field_input_geolocation input {
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 200ms ease-in-out;
    width: 100%;
    padding: 0.5rem;
    color: var(--eighthcolor);
}

.div_field_input_geolocation input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.div_field_input_geolocation input::placeholder {
    color: var(--eighthcolor);
}

.buttondDelete{
    padding: 0;
    border: none;
    background: none;
    font-size: 20px;
    outline: none;
    color: gray;
}

.btn_input_arquive::file-selector-button {
    border: none;
    background-color: var(--ninethcolor);
    box-shadow: 0 1px rgba(0, 0, 0, 0.4);
    transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
    outline: none;
    border-radius: 0.25rem;
    font-size: 12px;
    color:antiquewhite;
}

.btn_input_arquive::file-selector-button:hover{
    background-color: #0cddb3;
    cursor: pointer;
}

.btn_input_arquive::file-selector-button:active{
    transform: translateY(3px);
    box-shadow: 0 4px rgba(0, 0, 0, 0.6);
    outline: none;
  }
  
.btn_input_arquive::file-selector-button:focus{
    outline: none;
  }

.btn_open_waypoint {
    border: none;
    background-color: var(--ninethcolor);
    box-shadow: 0 1px rgba(0, 0, 0, 0.4);
    outline: none;
    border-radius: 0.25rem;
    font-size: 60%;
    color:antiquewhite;
    width: 17%;
    height: 60%;
    margin: 5px;
    transition: all 300ms ease-in-out;
}

.btn_open_waypoint :hover{
    background-color: #0cddb3;
}

.btn_open_waypoint:active{
    transform: translateY(3px);
    box-shadow: 0 4px rgba(0, 0, 0, 0.6);
    outline: none;
  }
  
.btn_open_waypoint:focus{
    outline: none;
  }

.coordinates-box {
    max-height: 200px; /* Set the maximum height you desire */
    overflow-y: auto; /* Add vertical scrollbar when content exceeds max height */
    border: 1px solid #ccc; /* Optional border for styling */
    padding: 10px; /* Optional padding for styling */
}