/* General CSS */
.div_top_header_dash {
    width: 100%;
    height: 33%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #f5f5f5;
}

.title_cardInfo {
    font-size: 15px;
    align-self: center;
    font-weight: 1000;
    margin: 0;
    padding-left: 0.5rem;
}

.div_footer_dash {
    width: 100%;
    height: 67%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_footer_dash div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 0.7rem;
}

.h1_cardInfo {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
}

.txt_cardInfo {
    color: #1ab394;
    margin: 0;
}

.btn_card_info, .btn_agency_info {
    background-color: #1ab394;
    width: 60px;
    height: 22px;
    border-radius: 4px;
    border: none;
    color: white;
    align-self: center;
    margin-right: 0.5rem;
}

/* AgencyInfo CSS */

.AgencyInfo {
    width: 31%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-top: 5px solid #e7eaec;
}

.btn_agency_info {
    background-color: #1c84c6;
}

/* EventsCityInfo CSS */
.EventsCityInfo {
    width: 40%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-top: 5px solid #e7eaec;
}

/* EmployeeInfo CSS */
.EmployeeInfo {
    width: 26%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-top: 5px solid #e7eaec;
}

/* AtractiveOperator */
.AtractiveOperator {
    width: 38%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 10px;
    border-top: 5px solid #e7eaec;
}

@media only screen and (max-width: 600px) {
    .AgencyInfo, .EventsCityInfo, .EmployeeInfo, .AtractiveOperator {
        width: 80%;
    }

    .AtractiveOperator {
        margin: 0;
    }

    .h1_cardInfo, .txt_cardInfo {
        font-size: 26px;
    }
    .txt_cardInfo {
        font-size: 11px;
    }

    .btn_agency_info, .btn_card_info {
        font-size: 10px;
    }

    .title_cardInfo { 
        font-size: 12px;
    }
}

@media only screen and (min-width: 1700px) {
    .h1_cardInfo, .txt_cardInfo {
        font-size: 2rem;
        font-weight: 700;
    }

    .txt_cardInfo {
        font-size: 1.1rem;
    }

    .btn_agency_info, .btn_card_info {
        font-size: 1.2rem;
    }

    .title_cardInfo { 
        font-size: 1.4rem;
    }

    .btn_card_info, .btn_agency_info {
        width: 90px;
        height: 32px;
        font-size: 1.2rem;
    }
}