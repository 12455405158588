.div_cardsdashboard {
    display: grid;
    width: 100%;
    grid-template-columns: 33.1% 33.1% 33.1%;
}

.div_cardInfo_dashboard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_cards_places_info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_cards_operator_segments_info {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.card_container_dash {
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 600px) {
    .div_cardsdashboard, .div_cards_operator_segments_info, .div_cards_places_info, .div_cardInfo_dashboard {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}