.AppAdmin{
    height: auto;
    width: auto;
}

:root {
    --firstcolor: white;
    --secondcolor: #f3f3f4;
    --thirdcolor: #2F4050;
    --fourthcolor: #293846;
    --fifthcolor: #2B3C4B;
    --sixthcolor: #B7BCC1;
    --seventhcolor: #696C6E;
    --eighthcolor: black;
    --ninethcolor: #18A689;
    --tenth: #f8ac58;
    --eleventhcolor: #e7eaec;
    --twenthcolor: #e8e9ea;
    --thirteenthcolor: #2d8f7b;
    --fourteenthcolor: #0cddb3;
    --fifteenthcolor: #16806a;
}

@font-face {
    font-family: opensans-regular;
    src: url(../fonts/OpenSans-Regular.ttf);
}

@font-face {
    font-family: opensans-bold;
    src: url(../fonts/OpenSans-Bold.ttf);
}

@font-face {
    font-family: opensans-semibold;
    src: url(../fonts/OpenSans-Semibold.ttf);
}

@font-face {
    font-family: opensans-light;
    src: url(../fonts/OpenSans-Light.ttf);
}