.EditInventory {
    min-height: 80vh;
}

.form_edit_admin {
    margin-top: 1rem;
    border-top: 5px solid var(--eleventhcolor);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--firstcolor);
}

.div_titles_edit_inventory h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
    padding: 4px;
    margin: 0;
}

.div_titles_edit_inventory h4 {
    font-size: 16px;
    padding: 6px;
}

/* All divs components*/
.div_edit_textarea_type, .div_edit_text_type, .div_edit_elements_type, .div_edit_select_type, .div_edit_radio_type, .div_edit_checkbox_type, .div_edit_image_type {
    margin: 0;
    padding: 5px;
}

.div_add_act {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: white;
}

.div_edit_radio_type, .div_edit_checkbox_type, .div_edit_checkbox_column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.div_edit_checkbox_type {
    width: 50%;
}

.div_edit_checkbox_row {
    padding: 5px;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.div_edit_checkbox_row_segments {
    grid-template-columns: 33.3% 33.3% 33.3%;
}

.div_edit_checkbox_row_comtur {
    grid-template-columns: 100%;
}

.div_edit_checkbox_row div {
    width: 100%;
}


.div_btn_edit_inventory {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 45%;
    height: 10vh;
}

.div_edit_image_type {
    height: 15vh;
}

.div_edit_map_type {
    height: 70vh;
    width: 50%;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_input_geolocation {
    width: 45%;
    height: fit-content;
    margin: 0;
    border: 0.5px solid #e8e9ea;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.txt_title_inventory, .subtext_inventory, .div_label_inventory label, .div_edit_checkbox_type label {
    font-weight: bold;
    color: #767676;
    font-size: 14px;
    margin: 0;
    padding: 2px;
}

.subtext_inventory {
    font-weight: normal;
    color: rgba(128, 128, 128, 0.849);
    width: 45%;
}

.input_text_inventory, .textarea_inventory, .select_inventory, .input_add_act {
    font-weight: lighter;
    font-size: 13px;
    width: 45%;
    margin: 0;
    padding: 6px;
    color: rgba(128, 128, 128, 0.849);
    border: 0.5px solid #e8e9ea;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
}

.input_geolocation {
    font-weight: lighter;
    font-size: 13px;
    color: rgba(128, 128, 128, 0.849);
    outline: none;
    background: none;
    border: none;
    width: 92%;
    padding: 6px;
}

.div_input_geolocation:focus-within {
    border: 1.5px solid #18A689;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.textarea_inventory:focus, .input_text_inventory:focus, .select_inventory:focus, .input_add_act:focus {
    border: 1.5px solid #18A689;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.input_add_act {
    width: 80%;
}

.textarea_inventory {
    height: 10vh;
    border: 0.5px solid rgba(128, 128, 128, 0.849);
}

.select_inventory {
    font-size: 15px;
}

.radio_input_inventory:checked, .radio_input_inventory.with-gap:checked {
    background-color: #767676;
}

.btn_edit_inventory, .btn_image_inventory, .btn_add_act, .btn_open_map {
    color: white;
    background-color: #18A689;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    width: 17%;
    height: 60%;
    margin: 5px;
    transition: all 300ms ease-in-out;
}

.btn_add_act {
    width: 20%;
    height: 32px;
    border-radius: 0px;
    align-self: center;
    margin: 0;
    padding: 0;
}

.btn_open_map {
    width: 8%;
    height: 2rem;
    margin: 0;
    padding: 0;
    transition: all 200ms ease-in-out;
}


.btn_image_inventory {
    width: 12%;
    height: 20%;
    transition: all 300ms ease-in-out;
}

.btn_image_inventory:focus {
    outline: none;
}

.btn_image_editCity {
    height: 2.4rem;
    transition: all 300ms ease-in-out;
}

.input_file_inventory {
    display: none;
}

.div_edit_image_type {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 45vh;
}

.div_image_editCity {
    height: fit-content;
}

.btn_edit_inventory:hover,  .btn_image_inventory:hover, .btn_add_act:hover, .btn_open_map:hover{
    background-color: #0cddb3;
}

.div_activities_edit {
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    width: 45.5%;
    height: 15vh;
}

.div_selects_neigh_align select::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 3px;
}

.div_selects_neigh_align select::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 5px;
}



.img_arrow_region {
    background: none;
    width: 100%;
}

.img_map_btn {
    width: 98%;
    height: 100%;
}

.div_align_multiple_select {
    width: 100%;
    padding: 0;
}

.div_align_multiple_select select {
    width: 100%;
}

.div_values_multiple_select select{
    height: 100%;
}

.divisor_component {
    border-top: 1px dashed var(--eleventhcolor);
    width: 95%;
    text-align: center;
}