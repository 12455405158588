.div_checkbox_input {
    width: 100%;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.label_checkbox_input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 1.5rem;
    width: 1.5rem;
}

.label_checkbox_input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark_input {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #eee;
    border: 2px solid #eee;
    transition: all 100ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;    
}

.label_checkbox_input:hover input ~ .checkmark_input {
    background-color: #ccc;
    border-color: #2d8f7b;
}

.label_checkbox_input input:checked ~ .checkmark_input {
    background-color: #2d8f7b;
    border-color: #2d8f7b;
}

.checkmark_input:after {
    content: "";
    position: absolute;
    display: none;
}

.label_checkbox_input input:checked ~ .checkmark_input:after {
    display: block;
}

.label_checkbox_input .checkmark_input:after {
    align-self: center;
    justify-self: center;
    margin-top: -15%;
    width: 35%;
    height: 60%;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.title_checkbox_input {
    padding-left: 1rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: var(--eighthcolor);
    cursor: pointer;
}

.input_other_option {
    width: 0;
    height: 1.5rem;
    align-self: flex-start;
    margin-left: 1rem;
    border: none;
    font-size: 1rem;
    color: var(--eighthcolor);
    background-color: transparent;
    transition: all 300ms ease-in-out;
    outline: none;
}