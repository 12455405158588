.RankingCalculator {
    height: 100%;
    width: 100%;
    background-color: var(--secondcolor);
    padding-left: 1.5%;
    padding-top: 2%;
    font-size: 1vw;
}

.btn_goback_ranking_cities {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 0.25rem;
    background-color: var(--ninethcolor);
    border: none;
    border-radius: 0.25rem;
    outline: none;
    transition: all 200ms ease-in-out;
    height: 2.5rem;
}

.btn_goback_ranking_cities span {
    color: white;
    justify-self: center;
    align-self: center;
    width: 70%;
}

.btn_goback_ranking_cities:hover {
    background-color: var(--fourteenthcolor);
}

.btn_goback_ranking_cities:active {
    outline: none;
}

.img_btn_goback_ranking_cities {
    width: 30%;
    justify-self: center;
    align-self: center;
}

.div_align_title_calculator {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.div_cards_pontuation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
}

.div_card_max_pontuation {
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: #1c84c6;
    color: #ffffff;
    width: 23%;
}

.div_card_curr_pontuation {
    background-color: #1ab394;
}

.div_card_setur_pontuation {
    background-image: linear-gradient(to bottom right, #FF4500	, #FFD700);
}

.div_align_card_max {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.div_img_card_max {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.div_img_card_max img {
    width: 99%;    
}

.div_info_card_max {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70%;
    text-align: right;
}

.title_score_calculator {
    font-weight: 600;
    font-family: inherit;
}

.div_align_cards_score {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
}

.div_align_cards_reports {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    margin-top: 1.5rem;
    width: 99%;
}

.ul_cards_reports {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding-top: 2rem;
    margin: 0;
    height: fit-content;
}

#list_cards_reports {
    height: fit-content;
    transition: all 800ms ease-out;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_title_cards_reports {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 5px solid var(--eleventhcolor);
    padding: 14px 15px 7px;
    height: 48px;
    border-bottom: 2px solid var(--secondcolor);
}

.div_title_cards_reports h5 {
    display: inline-block;
    font-size: 14px;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
    font-weight: 600;
    color: black;
}

.div_handle_size_cards_reports {
    width: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn_handle_size_calculator {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 55%;
    justify-self: flex-end;
    align-self: flex-end;
}

.btn_handle_size_calculator:focus {
    outline: none;
}

.img_handle_size_calculator {
    width: 100%;
    cursor: pointer;
}

.div_align_show_delete_cards {
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
}

.label_show_delete_cards {
    margin-left: 1rem;
    justify-self: center;
    align-self: center;
}

.label_show_delete_cards:hover input ~ .checkmark_cards_delete {
    background-color: #ccc;
    border-color: #1c84c6;
}

.label_show_delete_cards input:checked ~ .checkmark_cards_delete {
    background-color: #1c84c6;
    border-color: #1c84c6;
}

.label_text_delete_cards {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding-left: 0.5rem;
    cursor: pointer;
}