@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.loginAdm {
    align-items: center;
    background-color: #a26396;
    font-family: Calibri;
    height: 100vh;
    background-image: url(../assets/imgs/desk_bg.png);
}

/* Top Navbar */
.top_navbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #d35887;
    height: 10vh;
    width: 100%;
    overflow: auto;
    margin: 0;
    overflow: hidden;
}

.div_elements_nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon_destinos {
    width: 55px;
}

.title_nav {
    font-family: 'Lato', sans-serif;
    color: white;
}

/* Body form login */
.content_login {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 40vh;
    margin: 0 auto;
    align-items: center;
    margin-top: 20%;
    text-align: center;
}

/* Form */
.title_form {
    font-family: 'Lato', sans-serif;
    font-style: italic;
    font-style: bold;
    color: white;
}

.form_login {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 90%;
    height: 80%;
}

.body_form {
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

/* Fields */
.div_input_form {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 30%;
}

.field_login {
    border: none;
    border-bottom: 1px solid #d35887;
    background: transparent;
    width: 80%;
    color: white;
}

.icon_email_form {
    width: 6%;
}

.icon_pass_form {
    width: 6%;
}

.icon_show_pass {
    width: 7%;
    position: absolute;
    align-self: center;
    padding: 2px;
    left: 80%;
    cursor: pointer;
}

.icon_show_pass:active {
    background-color: #d687c8da;
}

.field_login::placeholder {
    color: white;
}

.field_login:focus {
    outline: none;
    border-bottom: 1px solid black;
}

.field_login:-webkit-autofill,
.field_login:-webkit-autofill:hover, 
.field_login:-webkit-autofill:focus, 
.field_login:-webkit-autofill:active{
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: white !important;
}

.div_forgot_password_admin{
    margin: 0 auto;
    width: 70%;
    height:35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.forgot_password_admin {
    color: white;
    cursor: pointer;
    width: 100%;
}

.forgot_password_admin:active {
    color: #cb3f22;
}

.btn_login_adm {
    background-color: #cb3f22;
    border: none;
    border-radius: 5px;
    height: 30%;
    width: 40%;
    margin: 0 auto;
    color: white;
    margin-top: 5%;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
    transition: background-color 0.5s, filter 0.5s;
    cursor: pointer;
}

.btn_login_adm:hover {
    background-color: #e65639;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition-delay: 100ms;
    transition-property: background-color, filter;
}

@media only screen and (min-width: 495px) { 
    /* Body form login */
    .content_login {
        width: 70%;
        margin-top: 10%;
    }

    .form_login {
        width: 70%;
    }

    .icon_show_pass {
        width: 4%;
        left: 67%;
    }   
}

@media only screen and (min-width: 1000px) { 
    /* Body form login */
    .content_login {
        width: 60%;
        margin-top: 8%;
    }

    .form_login {
        width: 50%;
        height: 40vh;
    }

    .body_form {
        height: 40vh;
    }

    .icon_show_pass {
        width: 2.3%;
        left: 61%;
    }
}

@media only screen and (min-width: 2150px) { 
    .icon_show_pass {
        width: 1.8%;
        left: 61%;
    }  
    * {
        font-size: 105%;
    }
}

@media only screen and (min-width: 3150px) { 
    .icon_show_pass {
        width: 1.8%;
        left: 61%;
    }  
    * {
        font-size: 115%;
    }

    .title_nav {
        font-size: 170%;
    }
    .icon_destinos {
        width: 170px;
    }
    .title_form {
        font-size: 150%;
    }
}