.RadioInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: 80px;
}

.div_label_input_radio {
    width: 18%;
    height: 13vh;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
}

.label_radio_input {
    width: 100%;
    text-align: right;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    padding: 0;
    margin: 0;
}

.div_out_input_radio {
    width: 75%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
}

.div_align_radio_input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 5vh;
}

.div_align_radio_input label {
    justify-self: center;
    align-self: center;
}

.label_text_radiomark {
    height: fit-content;
    align-self: center;
    margin: 0;
    font-weight: 400;
    font-style: bold;
    color: var(--eighthcolor);
    font-size: 1rem;
    margin-left: 0.8rem;
}


.label_radiomark_input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 26px;
    width: 26px;
    padding: 0;
    margin: 0;
}

.label_radiomark_input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.radiomark_radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: #eee;
    border: 2px solid #eee;
    transition: all 100ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
}

.label_radiomark_input:hover input ~ .radiomark_radio {
    background-color: #ccc;
    border-color: var(--thirteenthcolor);
}

.label_radiomark_input input:checked ~ .radiomark_radio {
    background-color: var(--thirteenthcolor);
    border-color: var(--thirteenthcolor);
}

.radiomark_radio:after {
    content: "";
    position: absolute;
    display: none;
}

.label_radiomark_input input:checked ~ .radiomark_radio:after {
    display: block;
}

.label_radiomark_input .radiomark_radio:after {
    align-self: center;
    justify-self: center;
    margin-top: -15%;
    width: 35%;
    height: 60%;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.label_text_radiomark {
    cursor: pointer;
}