.add_duration_btn {
  border: none;
  background-color: var(--ninethcolor);
  width: 30%;
  height: 45%;
  max-height: 50px;
  margin-left: 120px;
  box-shadow: 0 5px rgba(0, 0, 0, 0.4);
  transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
  outline: none;
  border-radius: 0.25rem;
  margin-bottom: 40px;
  font-size: 12px;
  color:antiquewhite;
}

.add_duration_btn:hover{
  background-color: var(--fourteenthcolor);
}

.add_duration_btn:active{
  transform: translateY(3px);
  box-shadow: 0 4px rgba(0, 0, 0, 0.6);
  outline: none;
}

.add_duration_btn:focus{
  outline: none;
}

.div_align_cabecalho {
  width: 100%;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.alignPonto {
  align-self: flex-start; /* Align to the start of the flex container */
}

.alignDuracao {
  position: absolute;
  left: 55%; 
}

.div_align_input_duration {
  display: flex;
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid gray;
}

.align_title_input {
  font-size: 16px;
  align-self: start;
}

.align_input {
  position: absolute;
  left: 55%;
}

.remove_duration_btn {
  position: absolute;
  left: 75%;
  border: none;
  background-color: var(--ninethcolor);
  width: 12%;
  height: 3.5%;
  max-height: 50px;
  box-shadow: 0 2px rgba(0, 0, 0, 0.4);
  transition: transform 30ms ease-in-out, box-shadow 30ms ease-in-out, background-color 200ms ease-in-out;
  outline: none;
  border-radius: 0.25rem;
  font-size: 12px;
  color:antiquewhite;
  
}