.VerifyPopup {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.32);;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: auto;
    z-index: 10;
}

.div-align-inner-popup {
    width: 500px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}

.div-title-verify-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 80px;
}

.div-title-verify-popup span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    color: #797979;
    text-align: center;
}

.div-message-verify-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 180px;
}

.div-message-verify-popup span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #616161;
}

.div-buttons-verify-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
    gap: 20px;
}

.div-buttons-verify-popup button {
    width: 70%;
    height: 50px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    border-radius: 25px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 100ms ease-in-out;
    cursor: pointer;
    outline: none;
}

.div-buttons-verify-popup button:active {
    outline: none;
}

.btn-confirm-verify-popup:disabled {
    background-color: var(--fifteenthcolor) !important;
}

..btn-cancel-verify-popup:disabled {
    background-color: #d4d4d4 !important;
}

.btn-cancel-verify-popup {
    background-color: #EEEEEE;
    color: #797979;
}

.btn-cancel-verify-popup:hover {
    background-color: #d4d4d4;
}

.btn-confirm-verify-popup {
    background-color: var(--ninethcolor);
    color: white;
}

.btn-confirm-verify-popup:hover {
    background-color: var(--fifteenthcolor);
}