@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.TitleEditor {
    width: 100%;
    background-color: var(--firstcolor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 18vh;
    padding: 0;
}

.div_top_card {
    width: 100%;
    height: 3%;
    background-color: var(--eleventhcolor);
}

.div_align_title_editor {
    width: 100%;
    height: 97%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    padding: 1rem;
}

.div_align_title_editor h2 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 100;
    font-size: 1.5rem;
}

.div_align_title_editor h6 {
    font-family: 'DM Sans', sans-serif;
    font-weight: 100;
    font-size: 1rem;
    color: var(--seventhcolor);
}