.label_check_pendencies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 40%;
    margin-top: 0.5rem;
  }
  
  .label_check_pendencies input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark_pendy {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #eee;
    border: 2px solid #eee;
    transition: all 100ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .label_check_pendencies:hover input ~ .checkmark_pendy {
    background-color: #ccc;
    border-color: #2d8f7b;
  }
  
  .label_check_pendencies input:checked ~ .checkmark_pendy {
    background-color: #2d8f7b;
    border-color: #2d8f7b;
  }
  
  .checkmark_pendy:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .label_check_pendencies input:checked ~ .checkmark_pendy:after {
    display: block;
  }
  
  .label_check_pendencies .checkmark_pendy:after {
    align-self: center;
    justify-self: center;
    margin-top: -15%;
    width: 35%;
    height: 60%;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }