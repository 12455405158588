@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@100;300;400;700;800&display=swap');

.TextInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 13vh;
    max-height: 80px;
}

.div_label_input_text {
    width: 18%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
}

.div_align_img_ranking_points {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 30px;

}

.div_align_img_ranking_points span {
    visibility: hidden;
    opacity: 0;
    width: 150px;
    background-color: #ced3e7;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1000;
    top: -50px;
    right: -75px;

    /* popup delay transition */
    transition: opacity 300ms ease-in-out;
    transition-delay: 1s;
    transition-property: opacity;
}

.checkbox_ranking_type {
    top: -70px !important;
}

.radio_ranking_type {
    top: -30px !important; 
}

.div_align_img_ranking_points img { 
    width:  30px;
}
  
.div_align_img_ranking_points span::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #ced3e7 transparent transparent transparent;
}
  
.div_align_img_ranking_points:hover span {
    visibility: visible;
    opacity: 1;
}

.label_text_input {
    width: 100%;
    text-align: right;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    padding: 0;
    margin: 0;
}

.div_out_input_range {
    width: 75%;
    align-self: center;
}

.div_out_input_range input {
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.div_out_input_range input::placeholder {
    color: var(--eighthcolor);
}

.div_out_input_range input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.error_message_text_input {
    color: red;
}