@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.HeaderForm {
    width: 100%;
    height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 1rem;
    border-bottom: 2px solid var(--secondcolor);
}

.HeaderQuestion {
    min-height: 8vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.HeaderPlaceId {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
}

.HeaderForm p {
    font-family: 'DM Sans', sans-serif;
    font-size: 0.75rem;
    font-weight: 100;
    margin: 0;
    padding: 0;
    color: var(--seventhcolor);
}

.div_align_handle_question_header {
    width: 8.5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_handle_question_header {
    width: 100% !important;
}

.title_question {
    justify-self: center;
    align-self: center;
}

.HeaderForm strong {
    font-size: 0.9rem;
    font-weight: 700;   
    color: var(--eighthcolor);
}

.btn_handle_question {
    width: 100% !important;
}

.img_handle_size_question {
    transition: all 300ms ease-in-out;
}

.btn_get_place_id {
    border: none;
    border-radius: 5px;
    background-color: var(--thirdcolor);
    color: white;
    font-size: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 150px;
    height: 40px;
    outline: none;
}

.btn_get_place_id:hover {
    background-color: var(--fourthcolor);
}