.CardInventoryAdmin {
    background-color: white;
    width: 40%;
    height: 109vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px;
    border-top: 5px solid #e7eaec;
}

.div_body_cardinvertory {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
}

.table_cardinventory {
    height: 100%;
}

.table_cardinventory th {
    background-color: #f5f5f6;
}

.td_cardinventory, .td_cardinventory_desc, .td_value_cardinventory {
    border: 1px solid #ebebeb;
    height: 30px;
}

.td_value_cardinventory {
    width: 16%;
}

.td_cardinventory, .td_value_cardinventory {
    text-align: center;
}

.td_cardinventory_desc {
    text-align: left;
    padding-left: 0.5rem;
}

.img_cardinventory {
    width: 46px;
}

.img_situation_icon_dashboard {
    width: 25%;
    margin: 0;  
}

.td_date {
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.td_date:hover {
    opacity: 0.4;
}


@media only screen and (max-width: 600px) {
    .CardInventoryAdmin {
        width: 80%;
        height: 128vh;
        margin: 0;
        font-size: 11px;
    }

    .CardInventoryAdmin h1 {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1700px) {
    .td_cardinventory_desc {
        font-size: 1.1rem;
    }
}