.RowPendencyCounter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.div_text_pendency_counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.div_text_pendency_counter p {
    margin-bottom: 0 !important;
}

.div_pendency_counter {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 3px;
    margin-right: 0.3rem;
    width: 3.3rem !important;
}

.div_pendency_counter p {
    color: var(--firstcolor);
    font-size: 11px;
    margin-bottom: 0 !important;
}