.RankingCities {
    height: 100%;
    width: 100%;
    background-color: var(--secondcolor);
    padding-left: 1.5%;
    padding-top: 2%;
    font-size: 1vw;
}

.div_table_score_ranking_cities {
    background-color: white;
    border-top: 2.5px solid var(--secondcolor);
    padding-top: 0.25rem;
    padding-bottom: 0;
}

.table_score_ranking_cities {
    margin-bottom: 0;
}

.th_ranking_cities {
    cursor: pointer;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
}

.th_ranking_cities:hover {
    opacity: 0.8;
}

.th_ranking_cities_descr, .td_ranking_cities_descr {
    width: 22%;
    text-align: left !important;
    padding-left: 1rem !important;
}

.th_ranking_cities_info {
    width: 12%;
}

.th_ranking_cities_more {
    width: 6%;
}

.info_table_ranking_cities {
    color:black;
    font-weight:400;
    font-size: 16px;
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.btn_show_more_ranking_cities {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--ninethcolor);
    border: none;
    border-radius: 0.25rem;
    width: 60%;
    margin: 0 auto;
    outline: none;
    transition: all 200ms ease-in-out;
}

.btn_show_more_ranking_cities:hover {
    background-color: var(--fourteenthcolor);
}

.btn_show_more_ranking_cities:active {
    outline: none;
}

.img_btn_show_more_ranking_cities {
    width: 100%;
    margin: 0 auto
}

.div_aling_filter_index {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 4rem;
}

.div_filter_index {
    width: 8%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
}

.btn_index_filter {
    width: 40px;
    height: 40px;
    border-radius: 0.25rem;
    background-color: var(--ninethcolor);
    display: flex;
    border: none;
    flex-direction: column;
    justify-content: center;
    transition: all 200ms ease-in-out;
    outline: none;
}

.btn_index_filter:hover {
    background-color: var(--fourteenthcolor);
}

.btn_index_filter:active, .btn_index_filter:focus {
    outline: none;
}

.img_btn_index_filter {
    width: 100%;
    margin: 0 auto;
}