.div_map_input {
    width: 90%;
    margin: 0 auto;
    height: 0;
    overflow: hidden;
    transition: all 300ms ease-in-out;
}

.div_align_map_input {
    overflow: visible !important;
    height: 100%;
    position: relative;
}

.div_error_load_map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.div_error_load_map p, .div_error_load_map h6 {
    color: var(--seventhcolor);
}