.TextField {
  border-radius: 12px;
  border: 1px solid #EBECF0;
  background: #EBF1FF;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
  height: 40px;
  flex-shrink: 0;
  padding-left: 2px;
}

.TextField img {
  width: 14px;
  align-self: center;
}

.TextField input {
  width: 76%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.TextField input::placeholder {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}