.ButtonForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 13vh;
}

.div_out_button_form {
    width: 84.7%;
    height: 10vh;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_align_button_form {
    width: 94.45%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.2rem;
}

.div_align_button_form button {
    height: 100%;
    max-height: 2.5rem;
    justify-self: center;
    align-self: center;
    margin: 0;
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    border-radius: 0.25rem;
    outline: none;
}

.div_align_button_form button:focus {
    outline: none;
}

.btn_cancel_changes {
    background-color: var(--firstcolor) !important;
    color: rgba(128, 128, 128, 0.849) !important;
    max-width: 6rem;
}

.btn_cancel_changes:hover {
    background-color: var(--twenthcolor) !important;
}