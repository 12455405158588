@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); 
@import url('https://fonts.googleapis.com/css?family=Lato');


.btn_goback_review_pendency {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 0.25rem;
    background-color: var(--ninethcolor);
    border: none;
    border-radius: 0.25rem;
    outline: none;
    transition: all 200ms ease-in-out;
    height: 2.5rem;
    margin: 15px;
}

.btn_goback_review_pendency span {
    color: white;
    align-self: center;
}

.div_info_pendency{
    display: flex;
}

.div_docs_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_info_doc {
    background-color: var(--firstcolor);
    border: 1px solid var(--secondcolor);
    width: 100%;
    padding: 1rem;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
    margin: 10px;
    font-family: 'Lato', sans-serif;
    border-radius: 0.5rem;
}

.div_title_doc{
    justify-content: center;
    display: flex;
    font-size: 1.5rem;
    padding-bottom: 10px;
    font-family: 'poppins', sans-serif;
    font-weight: bolder;
}

.div_align_review_pendency {
    width: 98.2%;
    margin: 0 auto;
    background-color: white;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
}

.btn_edit_pendency {
    width: 160px !important;
    height: 40px !important;
}