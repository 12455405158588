.spin-outside {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 2px solid white;
    border-bottom-color: var(--sixthcolor);
    border-radius: 50%;
    animation: spinner 2s linear infinite;
    margin: 0 auto;
    align-self: center;
    justify-self: center;
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}