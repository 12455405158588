@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@100;300;400;700;800&display=swap');

.FilePDF {
    width: 100%;
    background-color: #F6F7FC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.inner-popup-pdf {
    width: 80%;
    min-height: 90vh;
    background-color: #F6F7FC;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.div-align-btn-pdf {
    position: absolute;
    top: 20px;
    right: 8px;
    height: 100%;
    width: 200px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: right;
    gap: 20px;
    padding-right: 20px;
}

.div-btn-close-pdf, .div-btn-generate-pdf {
    width: 45px;
    height: 45px;
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15.5px;
    overflow: hidden;
    transition: all 200ms ease-in;
    background-color: #172B4D;
    border-radius: 5px;
    padding: 0;
    cursor: pointer;
    padding-left: 15.5px;
}

.div-btn-generate-pdf {
    top: 75px;
    background-color: white;
    border: 1px solid #EBECF0;
}

.div-btn-close-pdf:hover {
    width: 130px;
    background-color: #111C30;
}

.div-btn-generate-pdf:hover {
    width: 150px;
}

.img-close-pdf, .img-generate-pdf {
    width: 14px;
}

.div-btn-close-pdf span {
    color: var(--firstcolor);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.div-btn-generate-pdf span {
    color: #172B4D;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.div-align-body-pdf {
    width: 100%;
    min-height: 90vh;
    background-color: #F6F7FC;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    position: relative;
}

.div-body-pdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    padding: 35px;
}

.header-report-pdf {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--sixthcolor);
    gap: 10px;
    padding: 30px;
}

.header-report-pdf span {
    color: #172B4D;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.header-report-pdf span:first-child {
    font-weight: 900;
    font-size: 22px;
}

.body-report-pdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 70px;
}

.section-report-pdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #F6F7FC;
}

.div-title-pdf {
    height: 50px;
    color: #42526E;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    width: 70%;
    margin: 0 auto;
    text-align: left;
}

.div-table-pdf {
    width: 70%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: transparent;
}

.header-table-pdf tr {
    border-bottom: 2px solid #172B4D;
    background-color: transparent;
    height: 55px;
    padding: 15px;
}

.header-table-pdf tr th {
    font-family: 'Roboto', sans-serif;
    color: #172B4D;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.header-table-pdf tr th:first-child {
    text-align: left;
    padding-left: 40px;
}

.header-table-pdf tr th:last-child {
    text-align: center;
}

.row-table-pdf {
    background-color: var(--firstcolor);
    height: 30px;
    transition: all 200ms ease-in-out;
    border-bottom: 1px solid #EBECF0;
}

.row-table-pdf:hover { 
    background-color: #EBECF0;
}

.row-table-pdf td { 
    color: #42526E;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15px;
}

.row-table-pdf td:first-child{
    text-align: left;
    padding-left: 40px;
    width: 70%;
}

.row-table-pdf td:last-child{
    text-align: center;
}

.div-align-chart-pdf {
    width: 70%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
    gap: 30px;
}

@media print {
    .apexcharts-legend-marker {
      content: "\25A0";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      line-height: 12px;
      font-size: 24px;
      border-radius: 50px !important;
      align-self: center;
      justify-self: center;
      z-index: 10;
    }

    .apexcharts-legend-text {
        z-index: 5;
    }
  }