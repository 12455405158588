.CardScoreCalculator {
    width: 99%;
    background-color: white;
    border-top: 5px solid var(--eleventhcolor);
    padding-top: 1rem;
}

.title_card_calculator {
    font-weight: 600;
    font-size: 24px;
    padding-left: 1rem;
}

.div_handle_size_cards_score {
    width: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: right;
    margin-right: 1rem;
    cursor: pointer;
}

.img_handle_size_score {
    width: 50%;
}

.div_align_scores_ranking {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 2px solid var(--secondcolor);
}

.div_table_score_calculator {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    overflow: hidden;
    transition: all 300ms ease-in-out;
}

.table_score_calculator {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: 3rem;
}

.sub_rows_table_score, .row_table_score_calculator {
    height: 2.5rem;
    padding: 8px;
    line-height: 1.42857143;
    border-top: 1px solid #ddd;
}

.sub_rows_table_score {
    border-top: 1px solid #ddd;
}

.title_rows_table_score {
    border-top: 1px solid white;
    color: #676a6c;
    font-weight: 100;
}

.bg_gray {
    background-color: #f9f9f9;
}

.info_table_score {
    color:black;
    font-weight:400;
    font-size: 16px;
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.title_table_score {
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.short_td_table_score {
    width: 4%;
}

.medium_td_table_score {
    width: 12%;
}

.default_td_table_score {
    width: 8%;
}

.large_td_table_score {
    width: 51%;
    text-align: left;
}

.div_pie_chart_calculator {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin: 0 auto;
    background-color: #d7d7d7;
}