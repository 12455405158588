.CardHeatmap {
    background-color: white;
    width: 55vw;
    height: 109vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px;
    border-top: 5px solid #e7eaec;
}

.map_container {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    padding: 50px !important;
    flex-direction: column !important;
    justify-content: center !important;
    padding-top: 1rem !important;
    left: 0;
    top: 0;
}

.div_map_cardmap {
    width: 100%;
    height: 77%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    position: relative;
}

.div_align_stats_map {
    height: 13%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 45px;
    padding-right: 45px;
}

.div_map_buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 1rem;
    width: 35%;
}

.div_btn_align_map {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div_span_align_map {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    background-color: var(--eleventhcolor);
    max-width: 200px;
    margin: 0 auto;
}

.btn_setdate_map {
    border: none;
    box-shadow: gray 15px;
    width: 80px;
    height: 25px;
    font-size: 10px;
    font-weight: 700;
    border-radius: 3px;
    transition: all 300ms ease-in-out;
}

.btn_setdate_map:hover {
    background-color: rgb(220, 220, 220);
}

.div_btn_align_map p {
    font-size: 10px;
    margin: 0;
    padding: 0;
    align-self: center;
}

.div_span_align_map span {
    font-size: 14px;
    margin: 0;
    padding: 0;
    align-self: center;
}

.div_align_info_map {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.div_align_info_map p {
    font-size: 16px;
    margin: 0;
    padding: 0;
    align-self: center;
}

@media only screen and (max-width: 600px) {
    .CardHeatmap {
        width: 80%;
        height: 120vh;
        margin: 0;
    }

    .div_map_cardmap { 
        height: 70%;
    }

    .map_container {
        width: 80% !important;
    }

    .div_map_buttons {
        width: 100%;
    }
}

@media only screen and (min-width: 1700px) {
    .btn_setdate_map {
        width: 120px;
        height: 40px;
        font-size: 1rem;
    }

    .div_btn_align_map p {
        font-size: 14px;
        margin: 0;
        padding: 0;
        align-self: center;
    }
}