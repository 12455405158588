@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300&display=swap');

.CaeLogin {
  background: #EBF1FF;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  padding-top: 107px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main-container-cae-login {
  width: 1172px;
  height: 658px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 28px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  overflow: hidden;
}

.container-cae-login {
  width: 50%;
  height: 100%;
}

.header-container-cae-login {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  gap: 2px;
}

.container-cae-login:first-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  padding: 20px;
  padding-top: 51px;
  gap: 52px;
}

.header-container-cae-login span:first-child {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header-container-cae-login span:last-child {
  color: #42526E;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.title-container-cae-login {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.header-container-cae-login img {
  width: 100%;
}

.title-container-cae-login h1 {
  color: #172B4D;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.title-container-cae-login span {
  color: #42526E;
  font-family: Inter;
  font-size: 14.7px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.div-align-fields-cae-login {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  padding-top: 4px;
  align-items: center;
  margin: 0 auto;
}

.div-button-cae-login {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: -22px;
}

.div-button-cae-login button {
  border-radius: 30px;
  background: #172B4D;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 124px;
  height: 43px;
  border: none;
  color: #FFF;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  outline: none;
  transition: all 200ms ease-in-out;
}

.div-button-cae-login button:hover {
  background: #111C30;
}

.div-button-cae-login button:active, .div-button-cae-login button:focus {
  outline: none;
}

.hr-div-cae-login {
  width: 73%;
  margin: 0 auto;
  height: 2px;
  background: #EBECF0;
  margin-top: -20px;
}

.div-create-account-cae-login {
  width: 73%;
  margin: 0 auto;
  text-align: center;
  margin-top: -36px;
}

.div-create-account-cae-login span {
  color: #42526E;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.div-create-account-cae-login a {
  color: #172B4D;
  cursor: pointer;
  font-weight: 400;
}

.container-cae-login:last-child {
  background-image: url(../imgs/logo_login_embratur_background.svg);
}

.error-message-login {
  text-align: center;
  padding-top: 15px;
}

.message-notify-reset-password {
  color: #42526E;
  font-family: Inter;
  font-size: 14.7px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.footer-logo-destinos {
  width: 73%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100px;
  gap: 10px;
  margin-top: -15px;
}

.footer-logo-destinos img {
  width: 90px;
}

.footer-logo-destinos span {
  color: #42526E;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


@media only screen and (max-width: 1200px) {
  .main-container-cae-login {
    width: 550px;
  }

  .container-cae-login:first-child {
    width: 100%;
  }

  .container-cae-login:last-child {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  .CaeLogin {
    padding-top: 0;
  }

  .main-container-cae-login {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
  }

  .container-cae-login:first-child {
    width: 100%;
    height: 100%;
  }

  .main-container-cae-login {
    border-radius: 0;
  }

  .container-cae-login:last-child {
    display: none;
  }
  
  .header-container-cae-login, .title-container-cae-login, .div-align-fields-cae-login, .div-button-cae-login, .div-create-account-cae-login, .hr-div-cae-login, .footer-logo-destinos {
    width: 90%;
  }

  .div-form-cae-register div {
    width: 100% !important;
  }
}