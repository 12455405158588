.NoResultsComponent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 76vh;
    max-height: 76vh;
    text-align: center;
}

.img_not_found {
    width: 10%;
}

.title_not_found {
    color: var(--sixthcolor);
    font-size: 1.5rem;
    font-weight: 700;
}

.text_not_found {
    color: var(--sixthcolor);
}