.div_back_datepopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.div_inner_datepopup {
    position: relative;
    padding: 32px;
    width: 360px;
    height: 430px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px;
    padding: 25px;
    gap: 20px;
}

.div_btns_datepopup {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div_btns_datepopup button {
    align-self: center;
    font-size: 14px;
    font-family: Lato;
    width: 45%;
    height: 80%;
    border-radius: 5px;
    transition: all 200ms ease-in;
    color: white;
    background-color: #172B4D;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.div_btns_datepopup button:hover {
    background-color: #111C30;
}

.div_btns_datepopup button:first-child {
    background-color: white;
    color: black;
    border: 1px solid #EBECF0 !important;
}

.div_btns_datepopup button:first-child:hover {
    background-color: #EBECF0;
}

.calendar_component {
    height: 266px !important;
    width: 100% !important;
    font-size: 14px !important;
    margin: 0 auto;
}