h1, h2, h3, h4, p {
    font-family: 'Raleway', sans-serif;
}

.ListItinerary {
    min-height: 80vh;
    height:100%;
    width:100%;
}
.container {
    display: flex;
    flex-direction: column;
    height:100%;
    width:100%;
}

.title-wrapper {
    margin-bottom: 5%;
}

.pretitle {
    font-size: 2.3vw;
    font-weight: 300;
    color:rgb(113, 113, 113);
}

.title {
    font-size: 4.6vw;
    font-weight: 800;
}

.subtitle {
    font-size: 1.7vw;
    font-weight: 300;
}
