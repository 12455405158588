.CheckboxRender {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: fit-content;
}

.div_label_input_checkbox {
    width: 18%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 1rem;
}


.div_align_checkbox {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_checkbox_row_grid {
    width: 100%;
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 30% 30%;
    height: fit-content;
}

.div_checkbox_row_grid_2 {
    width: 100%;
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 50% 50% !important;
    height: fit-content;
    gap: 1rem;
}