.CardTableDashboard {
    background-color: white;
    width: 28%;
    height: 44vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px;
    border-top: 5px solid #e7eaec;
}

.div_headear_cardtable {
    width: 100%;
    height: 18%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px solid #f5f5f5;
    padding-left: 0.5rem;
}

.div_body_cardtable {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
}

.table_cardtable {
    width: 92%;
    height: 100%;
    margin: 0 auto;
    margin-top: 5%;
}

.title_cardtable {
    font-size: 15px;
    font-weight: 800;
    margin: 0;
}

.th_table_dashboard_column, .th_table_dashboard_semicolumn {
    background-color: #f5f5f6;
    border: 1px solid #ebebeb;
    padding-left: 0.5rem;
    height: 8px;
}

.th_table_dashboard_column {
    width: 75%;
}

.th_table_dashboard_semicolumn {
    width: 25%;
}

.table_cardtable .td_cardinventory_desc {
    width: 70%;
}

@media only screen and (max-width: 600px) {
    .CardTableDashboard {
        width: 80%;
        margin: 0;
    }

    .CardTableDashboard h1 {
        font-size: 14px;
    }

    .table_cardtable {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1700px) {
    .title_cardtable {
        font-size: 1.4rem;
    }

    .table_cardtable {
        font-size: 1.1rem;
    }
}