@import url('https://fonts.googleapis.com/css2?family=Jaldi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter:wght@100;200;300;500;600;700;800;900&family=Roboto:wght@100;300&display=swap');

.Cae {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
}

.header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
  background: #FFF;
}

.logo-div-header, .options-header {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.logo-div-header:first-child {
  padding-left: 15px;
}

.logo-div-header span {
  color: #000;
  font-family: 'Jaldi', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo-div-header img {
  width: 100px;
}

.options-header {
  position: relative;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
  margin-right: 15px;
  border-radius: 50px;
  cursor: pointer;
}

.options-header:hover {
  background: #EBECF0;
}

.img-options-header {
  cursor: pointer;
}

.popup-options-header span {
  padding-left: 5%;
  color: #172B4D;
  font-family: Jaldi;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.popup-options-header {
  position: absolute;
  right: 0;
  top: 48px;
  width: 312px;
  height: 120px;
  flex-shrink: 0;
  background: #EBECF0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: none;
}

.hr-div {
  background: #D9D9D9;
  width: 90%;
  margin: 0 auto;
  height: 1px;
}

.theme-options {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  height: 130px;
}

.div-theme {
  width: 140px;
  height: 51px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
}

.theme-options #active-theme {
  border: 2px solid #172B4D;
}

.theme-options-card {
  margin-top: 10px;
}

.radio-theme {
  border: 2px solid #172B4D;
}

.label-theme-options {
  color: #172B4D;
  font-family: Jaldi;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-buttons-options-header {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
}

.div-buttons-options-header div {
  height: 45px;
  flex-shrink: 0;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.div-buttons-options-header div:first-child {
  width: 144px;
  background: #FFF;
}

.div-buttons-options-header div img {
 padding: 0;
 height: 28px;
 width: 28px;
}

.div-buttons-options-header div:last-child {
  width: 119px;
  background: #172B4D;
}

.div-buttons-options-header div:first-child span {
  color: #172B4D;
  font-family: Jaldi;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.div-buttons-options-header div:last-child span {
  color: #EBECF0;
  font-family: Jaldi;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.body-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100vh - 72px);
}

.navbar-cae {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 267px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
  border-right: 1px solid rgba(0, 0, 0, 0.22);
  background: #FFF;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.handler-pages {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #F6F7FC;
  overflow-y: scroll;
}

.handler-pages::-webkit-scrollbar {
  width: 5px;
  background: #FFF;
}

.handler-pages::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #172B4D;
  border-radius: 5px;
}


.user-profile-navbar {
  width: 88%;
  height: 126px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  position: relative;
}

.user-profile-navbar .photo-user-navbar {
  width: 57px;
  height: 57px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #EBECF0;
}

.user-profile-navbar .info-user-navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  gap: 1px;
  transition: all 1000ms ease-in-out;
}

.user-profile-navbar .info-user-navbar span:first-child {
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.user-profile-navbar .info-user-navbar span:last-child {
  color: #42526E;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.handler-size-navbar {
  position: absolute;
  top: 49px;
  right: -30px;
  border-radius: 5px;
  background: #172B4D;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.handler-size-navbar img {
  transition: all 300ms ease-in-out;
}

.items-navbar {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100% - 180px);
  gap: 2px;
  width: 88%;
}

.items-navbar div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  gap: 18px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 5px;
  padding-left: 18px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.items-navbar #active {
  background: #EBECF0;
}

.items-navbar div:hover {
  background: #EBECF0;
}

.items-navbar div:hover span {
  color: #2280BD;
}

.items-navbar div:hover img {
  filter: invert(53%) sepia(52%) saturate(710%) hue-rotate(160deg) brightness(75%) contrast(95%);
}

.items-navbar #active span {
  color: #2280BD;
}

.items-navbar #active img {
  filter: invert(53%) sepia(52%) saturate(710%) hue-rotate(160deg) brightness(75%) contrast(95%);
}

.items-navbar div span {
  color: #42526E;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-navbar {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}

.footer-navbar span {
  color: #42526E;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.footer-navbar img {
  width: 80px;
}

@media only screen and (max-width: 1050px) {
  .navbar-cae {
    display: none;
  }

  .table-remove-responsive {
    display: none;
  }
}


@media only screen and (max-width: 600px) {
  .div-create-experiences {
    flex-direction: column;
    justify-content: center;
  }

  .div-search-experiences {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .section-medium-list {
    width: 100%;
  }

  .Experiences {
    padding: 15px;
  }

  .logo-div-header span {
    display: none;
  }
}


@media only screen and (max-width: 590px) {
  .row-radio-multiple, .subrow-radio-multiple {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .preview-image span {
    width: 50%;
    overflow: hidden;
  }
  
  .info-image span {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .table-remove-responsive-phone {
    display: none;
  }

  .section-medium-list {
    text-align: left;
    width: calc(100% - 55px);
  }

  .div-row-body-list, .div-row-header-list {
    justify-content: space-evenly;
  }
}