@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.DateInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 13vh;
    max-height: 80px;
}

.div_label_input_date {
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
}

.div_out_input_date {
    width: 75%;
    align-self: center;
}

.div_out_input_date input {
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
}

.div_out_input_date input::placeholder {
    color: var(--eighthcolor);
}

.div_out_input_date input:focus {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}