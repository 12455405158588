@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap');

.InventarioAdmin {
    height: 100%;
    width: 100%;
    background-color: var(--secondcolor);
    padding-left: 1.5%;
    padding-top: 2%;
    font-size: 1vw;
    
}

.div_title_inventory_adm h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 2.3vw;
}

.create_inventory_adm {
    color: #8ac0ec;
    cursor: pointer;
    font-size: 1.3vw;
    width: 20%;
}

.create_inventory_adm:hover {
    color: #4a81ad;
}

.div_browse_inventory_adm {
    width: 50%;
    height: 55px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    border: 2px solid var(--sixthcolor);
    border-radius: 5px;
    overflow: hidden;
    padding: 0;
    transition: all 300ms ease-in-out;
    box-shadow: -5px 3px 5px rgba(0, 0, 0, 0.19);
}

.div_browse_inventory_adm:focus-within {
    border: 2px solid var(--ninethcolor);
    box-shadow: -8px 6px 5px rgba(0, 0, 0, 0.19);
}

.div_browse_inventory_adm input, .div_browse_inventory_adm button {
    height: 100%;
    margin: 0;
    border: none;
    outline: none;
}

.div_browse_inventory_adm input::placeholder {
    color: var(--sixthcolor);
}

.div_browse_inventory_adm input {
    width: 90%;
    padding: 1rem;
    font-size: 1rem;
    color: var(--sixthcolor);
}

.div_browse_inventory_adm button {
    width: 10%;
    background: none;
    background-color: white;
    border-radius: 0;
}

.title_browse_inventory {
    height: 100%;
    margin: 2px;
    font-size: 1.1vw;
}

.input_browse_inventory {
    height: 100%;
    margin: 2px;
    font-size: 14px;
}

.btn_browse_inventory_adm {
    height: 27px;
    margin: 2px;
    border: 1px solid gray;
    font-size: 12px;
    border-radius: 10%;
}

.div_table_inventory_adm {
    width: 100%;
    min-height: 70vh;
}

.table_inventory_adm {
    width: 99.5%;
    height: 100%;
    font-size: 1.2vw;
    color: rgba(128, 128, 128, 0.849);
    box-shadow: -8px 4px 5px rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    overflow: hidden;
}

.table_inventory_adm td {
    height: 100%;
}

.row_table_inventory {
    border-bottom: 1px solid rgba(128, 128, 128, 0.164);
    height: 20vh;
}

.th_table_admin {
    height: 6rem;
}

.column_title_table_inventory {
    width: 10%;
    padding: 7px;
    color: black;
}

.column_check_table_inventory {
    width: 8%;
}

.column_desc_table_inventory {
    width: 34%;
    padding: 7px;
    color: black;
}

.column_table_inventory {
    width: 5%;
    padding: 7px;
    text-align: left;
    color: black;
}

.column_title_table_inventory, .column_desc_table_inventory, .column_table_inventory {
    cursor: pointer;
}

.column_title_table_inventory:hover, .column_desc_table_inventory:hover, .column_table_inventory:hover {
    color: #4a81ad;
}

.column_edit_table_inventory {
    width: 5%;
    display: flex;
    padding: 7px;
    flex-direction: column;
    justify-content: space-evenly;
}

.div_pagination_inventory {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn_pagination_inventory {
    background-color: transparent;
    cursor: pointer;
    border: none;
    width: 5%;
    font-size: 22px;
}

.btn_pagination_inventory:hover {
    background-color: rgba(128, 128, 128, 0.24);
}

.th_table_admin {
    background: linear-gradient(
    180deg,
    var(--fifthcolor) 40%,
    var(--fourthcolor) 100%
    );
    color: white;
}

.th_table_admin label, .th_table_admin input {
    cursor: pointer;
}

.div_align_pendencies {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.div_review_manyPendencies, .div_select_all_pendencies {
    opacity: 0;
    transition: all 150ms ease-in-out;
}

.div_review_manyPendencies {
    height: 55px;
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #2d8f7b;
    box-shadow: -5px 3px 5px rgba(0, 0, 0, 0.19);
    border-radius: 1rem;
    margin-left: 1rem;
}

.div_review_manyPendencies p {
    color: white;
    font-size: 1rem;
    font-weight: 100;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_review_manyPendencies button {
    height: 80%;
    width: 20%;
    border-radius: 1rem;
    justify-self: center;
    align-self: center;
    transition: all 150ms ease-in-out;
    outline: none;
}

.div_review_manyPendencies button:focus {
    outline: none;
}

#btn_approve_pendency {
    background-color: white;
    color: #2d8f7b;
    border: 2px solid #2d8f7b;
}

#btn_approve_pendency:hover {
    border-color: white;
    color: white;
    background: transparent;
}

#btn_refuse_pendency {
    border: 2px solid white;
    color: white;
    background: transparent;
}

#btn_refuse_pendency:hover {
    background-color: white;
    color: #2d8f7b;
    border-color: #2d8f7b;
}

.div_select_all_pendencies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.label_check_all_pendencies {
    height: 10px;
}
