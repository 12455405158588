.div_report_admin {
    width: 95%;
    background-color: white;
    border-radius: 5px;
    box-shadow: -4px 3px 10px rgba(0, 0, 0, 0.19);
    padding-top: 2rem;
}

.div_report_admin .content_b8sgnx {
    width: 90% !important;
}
