.CardInfoCalculator {
    width: 21%;
    margin-left: 2rem;
}

.div_card_info_calculator {
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #ffffff;
    width: 100%;
    height: 72%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_align_card_info_calculator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.img_type_score_calculator {
    width: 85%;
}