/* Columns */

.row_table_inventory_parent {
    height: 20vh;
    overflow: hidden;
    transition: height ease 0.3s;
    padding-inline: 134.5%;
    padding-bottom: 20vh;
}

.row_table_inventory tr {
    text-align: center;
}

.row_table_inventory_parent_show {
    height: auto;
    padding-inline: 300%;
}

.body_table {
    background-color: var(--firstcolor);
}

.title_table_inventory {
    width: 10%;
    padding: 7px;
}

.desc_table_inventory {
    width: 34%;
    padding: 7px;
}

.column_min_table_inventory {
    width: 5%;
    height: 100%;
}

/* Div's buttons */
.div_mod_inventory {
    height: 100%;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Buttons */
.btn_mod_adm {
    cursor: pointer;
    border: none;
    background: transparent;
    color: #8ac0ec;
    margin: 5px;
}

.btn_mod_adm:disabled{
  color: #666666;
  cursor: default;
}

.btn_mod_adm:disabled:hover{
    color: #666666;
  }

.btn_mod_adm:hover {
    color: #4a81ad;
}