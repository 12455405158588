.Footer {
  width: 100%;
  height: 66px;
  flex-shrink: 0;
  background: #172B4D;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Footer div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 25px;
}

.Footer div:first-child span {
  margin: 0;
  padding: 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.Footer div:last-child {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  gap: 26px;
}

.icon-footer {
  filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(283deg) brightness(7500%) contrast(101%);
  width: 20px;
  height: 20px;
}