.OptionEditorInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: fit-content;
}

.label_editor_input {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1rem;
}

.div_out_input_editor {
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0;
    padding: 0.5rem;
    color: var(--eighthcolor);
    border: 0.5px solid var(--twenthcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
    outline: none;
    width: 100%;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    background-color: white;
}

.div_align_options_editor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
}

.label_option_question {
    width: 14%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.div_out_input_editor input {
    background-color: var(--secondcolor);
    border-radius: 0.25rem;
    border: none;
    width: 80%;
    outline: none;
    padding-left: 1rem;
}

.div_out_input_editor input:focus {
    outline: none;
}

.div_out_input_editor input::placeholder {
    color: var(--eighthcolor);
}

.div_out_input_editor:focus-within {
    border: 1.5px solid var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    outline: none;
}

.btn_delete_option_editor {
    width: 4%;
    border-radius: 0.25rem;
    border: none;
    outline: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--ninethcolor);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: all 200ms ease-in-out;
}

.btn_delete_option_editor:hover {
    background-color: var(--fourteenthcolor);
}

.btn_delete_option_editor:active, .btn_delete_option_editor:focus {
    outline: none;
}

.btn_delete_option_editor:disabled {
    background-color: #c4c4c4;
}

.img_delete_option_editor {
    width: 100%;
    margin: 0 auto;
}

.div_add_new_option {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.div_btn_add_option {
    width: 38px !important;
    height: 38px !important;
    border-radius: 50% !important;
}

.img_add_option {
    width: 90%;
    margin: 0 auto;
}