.div_act_name_list {
    background-color: #18A689;
    display: inline-block;
    flex-direction: row;
    justify-content: center;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    height: 1.7rem;
}

.div_align_div_activity {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0.3rem;
}

.div_align_component_activity {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.1rem;
}

.div_act_name_list:hover {
    background-color: #0cddb3;
}

.text_act_edit {
    font-size: 16px;
    color: white;
    align-self: center;
    justify-self: center;
    text-align: left;
}

.remove_icon_edit {
    width: 22px;
    cursor: pointer;
    align-self: center;
    justify-self: center;
}